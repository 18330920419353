import React from "react";
import styled from "@emotion/styled";
import { API, Auth } from "aws-amplify";

import Button from "../../common/Button";

const StyledWrapper = styled.div`
  margin-bottom: 15px;
`;

const MovieTitle = styled.strong`
  text-decoration: underline;
  font-size: inherit;
`;

class Add extends React.Component {
  state = {
    movieInfo: {},
    isLoading: false,
  };

  componentDidMount = async () => {
    const { uiData } = this.props.match.params;
    const {
      suggestedBy,
      id,
      title,
      release_date,
      vote_average,
      poster_path,
    } = JSON.parse(atob(uiData));

    this.setState({
      movieInfo: {
        suggestedBy,
        id,
        title,
        release_date,
        vote_average,
        poster_path,
      },
    });
  };

  addToSuggestions = async () => {
    const {
      movieInfo: { suggestedBy, id },
    } = this.state;
    const { history } = this.props;

    try {
      await Auth.currentAuthenticatedUser();
    } catch (e) {
      history.push(`/auth/signin?redirectTo=${window.location.pathname}`);
      return;
    }

    this.setState((state) => ({ ...state, isLoading: true }));

    await API.post(
      "api",
      `/suggestions/${btoa(JSON.stringify({ suggestedBy, id }))}`
    );

    history.push("/timeline");
  };

  render() {
    const { movieInfo, isLoading } = this.state;

    return (
      <>
        <StyledWrapper>
          <h2>
            {movieInfo.suggestedBy} wants you to watch{" "}
            <MovieTitle>
              {movieInfo.title} (
              {new Date(movieInfo.release_date).getFullYear()})
            </MovieTitle>
          </h2>
          <img
            src={`https://image.tmdb.org/t/p/w500${movieInfo.poster_path}`}
            alt={movieInfo.title}
            width={200}
          />
        </StyledWrapper>
        <Button onClick={this.addToSuggestions} disabled={isLoading}>
          Add to your suggestions
        </Button>
      </>
    );
  }
}

export default Add;
