import React from "react";
import styled from "@emotion/styled";

const StyledItem = styled.div`
  padding: 6px 10px;
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: space-between;

  ${({ isActive }) => isActive && `font-weight: bold; background: #eeeeee;`}
`;

const Item = ({ item, isActive, ...other }) => (
  <StyledItem {...other} isActive={isActive}>
    <span>
      {item.title} ({new Date(item.release_date).getFullYear()})
    </span>
    <span>{item.type}</span>
  </StyledItem>
);

export default Item;
