import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Auth } from "aws-amplify";

import Action from "../common/Action";
import Icon from "../common/Icon";
import Masthead from "../common/Masthead";

const SignUp = styled(Action)`
  margin-top: 20px;
`;

const StyledIcon = styled(Icon)`
  font-size: 24px;
`;

const Home = ({ history }) => {
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    const checkAuthenticated = async () => {
      try {
        await Auth.currentAuthenticatedUser();

        history.replace("/timeline");
      } catch (e) {
        setShouldDisplay(true);
      }
    };

    checkAuthenticated();
  });

  if (!shouldDisplay) {
    return <StyledIcon name="spinner-third" spin />;
  }

  return (
    <>
      <Masthead />
      <SignUp to="/auth/signup">Sign up</SignUp>
    </>
  );
};

export default Home;
