import React from "react";
import classNames from "classnames";
import styled from "@emotion/styled";

const StyledIcon = styled("i")`
  font-size: inherit;
`;

const Icon = ({ name, spin, className }) => (
  <StyledIcon
    className={classNames(className, "fa", `fa-${name}`, { "fa-spin": spin })}
  />
);

export default Icon;
