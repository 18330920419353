import React from "react";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import Input from "../../common/Input";
import Action from "../../common/Action";
import Link from "../../common/Link";

const Error = styled.span`
  display: block;
  margin-top: 12px;
  color: #a50000;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Login = styled(Action)`
  margin-right: 16px;
`;

const fields = [
  { id: "username", label: "Username", type: "text" },
  { id: "password", label: "Password", type: "password" },
];

export default class Join extends React.Component {
  state = {
    fields: {},
    error: "",
  };

  updateValue = (id) => ({ target: { value } }) => {
    const { fields } = this.state;
    this.setState({ fields: { ...fields, [id]: value } });
  };

  login = async (e) => {
    e.preventDefault();

    const { history } = this.props;
    const { username, password } = this.state.fields;

    try {
      const result = await Auth.signIn(username, password);

      if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
        history.push(`/auth/change-password/${username}`);
      }

      if (result.signInUserSession) {
        const redirectTo = new URLSearchParams(window.location.search).get(
          "redirectTo"
        );

        history.push(redirectTo ? redirectTo : "/timeline");
      }
    } catch (e) {
      console.error(e);

      this.setState({
        error: e.message,
      });
    }
  };

  render() {
    const { error } = this.state;
    const redirectTo = new URLSearchParams(window.location.search).get(
      "redirectTo"
    );

    return (
      <div>
        <form onSubmit={this.login}>
          {fields.map(({ id, label, type }) => (
            <Input
              key={id}
              type={type}
              onChange={this.updateValue(id)}
              value={this.state.fields[id] || ""}
              label={label}
              required
            />
          ))}
          <Actions>
            <Login as="button" type="submit">
              Login
            </Login>
            <Link
              to={
                redirectTo
                  ? `/auth/signup?redirectTo=${redirectTo}`
                  : `/auth/signup`
              }
            >
              Create an account
            </Link>
          </Actions>
        </form>
        {error && <Error>{error}</Error>}
      </div>
    );
  }
}
