import React from "react";
import { Auth } from "aws-amplify";

const requiresAccount = function(Component) {
  return class RequiresAccount extends React.Component {
    state = {
      isAuthenticated: false
    };

    async componentDidMount() {
      const { history } = this.props;

      try {
        await Auth.currentAuthenticatedUser();

        this.setState({ isAuthenticated: true });
      } catch (e) {
        history.replace("/");
      }
    }

    render() {
      const { isAuthenticated } = this.state;
      return isAuthenticated && <Component {...this.props} />;
    }
  };
};

export default requiresAccount;
