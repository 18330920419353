import React from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  display: inline-block;
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0;
  cursor: pointer;
  font-weight: bold;

  :hover,
  :focus {
    border-color: #d3d3d3;
    outline: 0;
  }
  :disabled {
    border-color: #eee;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Button = props => {
  return <StyledButton {...props} />;
};

export default Button;
