import React from "react";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import Input from "../../common/Input";
import Action from "../../common/Action";
import Error from "../../common/Error";

const SignUpButton = styled(Action)`
  margin-top: 20px;
`;

const FIELDS = [
  { id: "username", label: "Choose a username", type: "text" },
  { id: "password", label: "Pick a password", type: "password" },
];

class SignUp extends React.Component {
  state = {
    fields: {},
    error: "",
  };

  updateValue = (id) => ({ target: { value } }) => {
    const { fields } = this.state;
    this.setState({ fields: { ...fields, [id]: value } });
  };

  join = async (e) => {
    e.preventDefault();

    const { history } = this.props;
    const { username, password } = this.state.fields;

    try {
      await Auth.signUp({
        username,
        password,
      });

      await Auth.signIn(username, password);

      const redirectTo = new URLSearchParams(window.location.search).get(
        "redirectTo"
      );

      history.push(redirectTo ? redirectTo : "/timeline");
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  render() {
    const { fields, error } = this.state;

    return (
      <form onSubmit={this.join}>
        {FIELDS.map(({ id, label, type }) => (
          <Input
            key={id}
            type={type}
            onChange={this.updateValue(id)}
            value={fields[id] || ""}
            label={label}
            required
          />
        ))}
        <SignUpButton as="button" type="submit">
          Sign up
        </SignUpButton>
        {error && <Error>{error}</Error>}
      </form>
    );
  }
}

export default SignUp;
