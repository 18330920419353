import React from "react";
import styled from "@emotion/styled";

const Description = styled.p`
  font-size: ${({ small }) => (small ? "20px" : "40px")};
  color: #132238;
`;

const Masthead = () => (
  <>
    <Description>Losing track of movie recommendations?</Description>
    <Description small>
      For when you're bored and need something to watch.
    </Description>
  </>
);

export default Masthead;
