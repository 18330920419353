import React from "react";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import Input from "../../common/Input";
import Action from "../../common/Action";
import Error from "../../common/Error";

const Change = styled(Action)`
  margin-top: 20px;
`;

const fields = [
  { id: "oldPassword", label: "Old password", type: "password" },
  { id: "newPassword", label: "New password", type: "password" },
];

export default class ChangePassword extends React.Component {
  state = {
    fields: {},
    error: "",
  };

  updateValue = (id) => ({ target: { value } }) => {
    const { fields } = this.state;
    this.setState({ fields: { ...fields, [id]: value } });
  };

  changePassword = async (e) => {
    e.preventDefault();

    const { history } = this.props;
    const { match } = this.props;
    const {
      params: { username },
    } = match;
    const { oldPassword, newPassword } = this.state.fields;

    try {
      const user = await Auth.signIn(username, oldPassword);

      await Auth.completeNewPassword(user, newPassword, []);

      history.push(`/timeline`);
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <div>
        <form onSubmit={this.changePassword}>
          {fields.map(({ id, label, type }) => (
            <Input
              key={id}
              type={type}
              onChange={this.updateValue(id)}
              value={this.state.fields[id] || ""}
              label={label}
              required
            />
          ))}
          <Change as="button" type="submit">
            Change
          </Change>
        </form>
        {error && <Error>{error}</Error>}
      </div>
    );
  }
}
