import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import Icon from "../../common/Icon";

const StyledIcon = styled(Icon)`
  font-size: 24px;
`;

const SignOut = ({ history }) => {
  useEffect(() => {
    const signOut = async () => {
      await Auth.signOut();
      history.push("/");
    };

    signOut();
  }, [history]);

  return <StyledIcon name="spinner-third" spin />;
};

export default SignOut;
