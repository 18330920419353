import React from "react";
import { API } from "aws-amplify";
import styled from "@emotion/styled";

import Icon from "../../common/Icon";
import MovieDetail from "../../common/MovieDetail";
import Action from "../../common/Action";

const New = styled(Action)`
  margin: 0 0 20px;
`;

const Loading = styled("div")`
  font-size: 32px;
  margin-top: 10px;
`;

class Timeline extends React.Component {
  state = {
    suggestions: [],
    isLoaded: false,
  };

  async componentDidMount() {
    const { suggestions } = await API.get("api", `/suggestions`);

    this.setState({
      isLoaded: true,
      suggestions: suggestions.map(
        ({ movie_id, suggested_by, release_year, ...rest }) => ({
          id: movie_id,
          suggestedBy: suggested_by,
          releaseYear: release_year,
          ...rest,
        })
      ),
    });
  }

  handleRate = async (id, liked) => {
    await API.post("api", `/suggestion/${id}`, {
      body: { liked },
    });
  };

  render() {
    const { isLoaded, suggestions } = this.state;
    const hasSuggestions = suggestions.length > 0;

    return (
      <>
        <New to="/timeline/new">New suggestion</New>
        {!isLoaded && (
          <Loading>
            <Icon name="spinner-third" spin />
          </Loading>
        )}
        {isLoaded &&
          hasSuggestions &&
          suggestions.map((item) => (
            <MovieDetail key={item.id} {...item} onRate={this.handleRate} />
          ))}
        {isLoaded && !hasSuggestions && (
          <p>Nothing here yet, ask your friends!</p>
        )}
      </>
    );
  }
}

export default Timeline;
