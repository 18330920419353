import React from "react";
import styled from "@emotion/styled";
import { Link as RouterLink } from "react-router-dom";

import Link from "../Link";

const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-top: 1px solid #f7f7f7;
  padding: 12px 0;
  text-decoration: none;
`;

const Title = styled.h2`
  margin: 0;
`;

const MovieDetail = ({
  id,
  suggestedBy,
  poster,
  title,
  description,
  rating,
  releaseYear,
  onRate,
}) => {
  return (
    <Container as={RouterLink} to={`/timeline/detail/${id}`}>
      <Title>{title}</Title>
      <span>></span>
    </Container>
  );
};

export default MovieDetail;
