import React from "react";
import { API } from "aws-amplify";
import Downshift from "downshift";
import throttle from "lodash.throttle";
import styled from "@emotion/styled";

import Input from "../Input";
import Icon from "../Icon";
import Item from "./Item";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const StyledItems = styled.div`
  z-index: 999;
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  background: white;
  border: 1px solid #d3d3d3;
  border-top: 0;
  box-sizing: border-box;
`;

const SelectedItem = styled(Item)`
  background: white;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
  cursor: text;
`;

class Search extends React.Component {
  state = {
    value: "",
    loading: false,
    suggestions: [],
    selectedItem: {},
  };

  searchRef = React.createRef();

  search = throttle(async (value) => {
    if (this.searchRef.current) {
      await API.cancel(this.searchRef.current);
    }

    const { results } = (this.searchRef = await API.get(
      "api",
      `/search?value=${value}`
    ));

    this.setState(() => ({
      loading: false,
      suggestions: results,
    }));
  }, 1000);

  getSuggestions = ({ target: { value } }) => {
    const willSearch = value.length >= 3;

    this.setState(() => ({
      loading: willSearch && true,
      value,
    }));

    if (willSearch) {
      this.search(value);
    }
  };

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  };

  onSelect = (suggestion) => {
    const { onSelect } = this.props;

    onSelect && onSelect(suggestion);

    this.setState({ selectedItem: suggestion });
  };

  reset = () => {
    const { onReset } = this.props;

    onReset && onReset();

    this.setState({ selectedItem: {} });
  };

  render() {
    const { value, loading, suggestions, selectedItem } = this.state;
    const hasSelectedItem = selectedItem.title;

    const inputProps = {
      placeholder: "Search..",
      value,
      onChange: this.getSuggestions,
    };

    return (
      <Downshift
        onChange={this.onSelect}
        itemToString={(item) => item.title}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div>
            <StyledWrapper>
              {!hasSelectedItem && <Input {...getInputProps(inputProps)} />}
              {hasSelectedItem && (
                <SelectedItem item={selectedItem} onClick={this.reset} />
              )}
              {loading && isOpen && <StyledIcon name="spinner" spin />}
              {isOpen && (
                <StyledItems>
                  {suggestions.map((item, index) => (
                    <Item
                      {...getItemProps({ item })}
                      key={index}
                      item={item}
                      isActive={
                        highlightedIndex === null ||
                        highlightedIndex > suggestions.length
                          ? 0
                          : highlightedIndex === index
                      }
                    />
                  ))}
                </StyledItems>
              )}
            </StyledWrapper>
          </div>
        )}
      </Downshift>
    );
  }
}

export default Search;
