import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import Link from "../common/Link";

const StyledLink = styled(Link)`
  padding: 6px 0;
  text-decoration: none;
`;

const AuthLink = () => {
  const [isLoggedIn, setIsLoggedIn] = useState();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();

        setIsLoggedIn(true);
      } catch (e) {
        setIsLoggedIn(false);
      }
    };

    checkAuth();
  });

  if (isLoggedIn === undefined) {
    return null;
  }

  return isLoggedIn ? (
    <StyledLink to="/auth/signout">Sign out</StyledLink>
  ) : (
    <StyledLink to="/auth/signin">Sign in</StyledLink>
  );
};

export default AuthLink;
