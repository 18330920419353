import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const Component = styled(Link)`
  display: inline-block;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  background: #132238;
  color: white;
  cursor: pointer;
  border: 0;
`;

const Action = ({ as = Link, ...rest }) => {
  const C = Component.withComponent(as);

  return <C {...rest} />;
};

export default Action;
