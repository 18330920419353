import React from "react";
import styled from "@emotion/styled";

const StyledErrorMessage = styled.span`
  display: block;
  color: red;
  margin-top: 20px;
`;

const Error = ({ children, ...other }) => (
  <StyledErrorMessage {...other}>&#9888; {children}</StyledErrorMessage>
);

export default Error;
