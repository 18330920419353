import { Auth } from "aws-amplify";

const {
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_GATEWAY_URL,
} = process.env;

const apiUrl = `https://${REACT_APP_GATEWAY_URL}`;

const config = {
  apiUrl,
  cognito: {
    REGION: REACT_APP_AWS_REGION,
    USER_POOL_ID: REACT_APP_USER_POOL_ID,
    USER_POOL_CLIENT_ID: REACT_APP_USER_POOL_CLIENT_ID,
  },
  gateway: {
    ENDPOINTS: [
      {
        name: "api",
        endpoint: apiUrl,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          };
        },
      },
    ],
  },
};

export default config;
