import React from "react";
import styled from "@emotion/styled";
import { Link as RouterLink } from "react-router-dom";

const StyledLink = styled(RouterLink)`
  text-decoration: underline;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  color: #132238;

  &:focus {
    outline: 1px solid #d3d3d3;
  }
`;

const StyledButton = StyledLink.withComponent("button");

const Link = ({ to, onClick, ...other }) => {
  if (to) {
    return <StyledLink {...other} to={to} />;
  }

  return <StyledButton {...other} onClick={onClick} type="button" />;
};

export default Link;
