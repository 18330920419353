import React from "react";
import { Auth } from "aws-amplify";
import copy from "copy-to-clipboard";

import config from "../../config";
import Search from "../../common/Search";
import Button from "../../common/Button";

class Timeline extends React.Component {
  state = {
    selection: {},
    suggestions: [],
    canShare: false,
    hasCopied: false,
    isLoaded: false,
  };

  async componentDidMount() {
    const canShare = Boolean(navigator.canShare);

    this.setState({
      canShare,
    });
  }

  onSearchSelect = (selection) => {
    this.setState({
      ...this.state,
      selection,
    });
  };

  onSearchReset = () => {
    this.setState({
      id: "",
    });
  };

  copyOrShare = async (e) => {
    e.preventDefault();

    const { username } = await Auth.currentUserInfo();
    const { selection, canShare } = this.state;

    const suggestion = btoa(
      JSON.stringify({
        suggestedBy: username,
        id: selection.id,
      })
    );

    const url = `${config.apiUrl}/share/${suggestion}`;

    try {
      if (canShare) {
        await navigator.share({
          title: `New shuggestion!`,
          text: `${username} wants you to watch ${selection.title}`,
          url,
        });
      } else {
        copy(url);
        this.setState({ hasCopied: true });
      }
    } catch (e) {}
  };

  render() {
    const { selection, hasCopied, canShare } = this.state;
    const isDisabled = !selection.id;

    return (
      <>
        <form onSubmit={this.copyOrShare}>
          <Search onSelect={this.onSearchSelect} onReset={this.onSearchReset} />
          <Button disabled={isDisabled}>
            {canShare && "Share"}
            {!canShare && (!hasCopied ? "Copy link to clipboard" : "Copied!")}
          </Button>
        </form>
      </>
    );
  }
}

export default Timeline;
