import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

const Detail = ({ match: { params } }) => {
  const [suggestion, setSuggestion] = useState(undefined);
  const { id } = params;

  useEffect(
    () => {
      const fetchSuggestion = async () => {
        const suggestion = await API.get("api", `/suggestion/${id}`);

        setSuggestion(suggestion);
      };

      fetchSuggestion();
    },
    [id]
  );

  if (!suggestion) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <h2>{suggestion.title}</h2>
      <p>{suggestion.suggested_by} suggested this to you</p>
      <img src={suggestion.poster} alt={`${suggestion.title} poster`} />
    </div>
  );
};

export default Detail;
