import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Amplify, Auth, API } from "aws-amplify";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

import requiresAccount from "./decorators/requiresAccount";
import {
  Home,
  SignUp,
  SignIn,
  SignOut,
  ChangePassword,
  Timeline,
  Add,
  New,
  Detail,
} from "./pages/index";
import config from "./config";
import Link from "./common/Link";
import AuthLink from "./components/AuthLink";

const amplifyConfig = {
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: config.gateway.ENDPOINTS,
  },
};

Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig);
API.configure(amplifyConfig);

const globalStyles = css`
  * {
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.25;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  body {
    margin: 0;
    padding: 0 20px;
  }
`;

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Link)`
  display: inline-block;
  font-size: 2rem;
  margin: 20px 0 30px;
`;

ReactDOM.render(
  <Router>
    <MainContainer>
      <Global styles={globalStyles} />
      <Header>
        <Title to="/timeline">shuggest</Title>
        <Route path="*" component={AuthLink} />
      </Header>
      <Route path="/" exact component={Home} />
      <Route path="/auth/signup" component={SignUp} />
      <Route path="/auth/signin" component={SignIn} />
      <Route path="/auth/signout" component={SignOut} />
      <Route
        path="/auth/change-password/:username"
        component={ChangePassword}
      />
      <Route path="/timeline" exact component={requiresAccount(Timeline)} />
      <Route path="/timeline/new" exact component={requiresAccount(New)} />
      <Route
        path="/timeline/detail/:id"
        exact
        component={requiresAccount(Detail)}
      />
      <Route path="/share/add/:uiData" component={Add} />
    </MainContainer>
  </Router>,
  document.getElementById("root")
);
