import React from "react";
import styled from "@emotion/styled";

const StyledLabel = styled.span`
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 6px 10px;
  background: white;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;

  &:hover,
  &:focus {
    border-color: #d3d3d3;
    outline: 0;
  }

  &:disabled {
    border-color: #eeeeee;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Input = ({ label, className, ...other }) => {
  return (
    <label>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput {...other} />
    </label>
  );
};

export default Input;
